import React from 'react'
import Menu from './Menu'

function Header() {
  return (
    <div className="flex bg-palette-dark-blue">
        <Menu />
    </div>
  );
}

export default Header