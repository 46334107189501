import React from 'react'
import telegram from '../images/telegram.svg'
import uk from '../images/united-kingdom.svg'

function Content() {
  return (
    <div className="flex-grow bg-palette-light-blue text-center">
      <div className="bg-palette-mid-blue p-2"></div>
      <div className="sm:text-2xl font-thin bg-palette-pale-blue text-grey-300 p-10">
        Welcome to ADA.ZONE, a Staking Pool for the Cardano Blockchain. <br />
        <div className="flex items-center justify-center mt-4">
          <div className="p-2 flex items-center justify-center bg-palette-dark-blue rounded-full">
            <img className="w-20 h-20 mr-2" src={telegram} alt="Join us on telegram" />
            <a className="sm:text-xl text-sm font-bold" href="https://t.me/adazone">
              Join us on Telegram
            </a>
          </div>
        </div>
      </div>

      <div className="md:flex m-4 lg:m-auto lg:mt-4 lg:mb-4 lg:max-w-6xl">
        <div className="md:flex-1 lg:ml-4 flex flex-col rounded shadow-medium bg-palette-lighter-blue min-h-auto p-4">
          <div className="text-xl p-2 text-palette-dark-blue">Key Information</div>
          <div>
            <div className="flex flex-col text-left">
              <div className="flex items-center font-bold">
                <img className="w-20 h-20 mr-2 " src={uk} alt="uk flag" /> UK Team
              </div>
              <div className="py-4">
                We are Software Engineering professionals based in London. Have extensive experience in buidling,
                deploying and monitoring applications for some of the largest UK and worldwide brands.
              </div>
              <div className="flex items-center font-bold">
                <img className="w-20 h-20 mr-2" src={uk} alt="uk flag" /> Hosted in London, UK
              </div>
              <div className="py-4">We've selected a host in London to represent the UK in Cardano's presence.</div>
            </div>
          </div>
        </div>
        {/* <div className="md:flex-1 flex flex-col rounded shadow-medium bg-white mt-4 md:mx-4 md:mt-0 min-h-full p-4">
          <div className="text-xl p-2 text-grey-800">Monitoring</div>
          <div>
            <div className="flex flex-col bg-grey-100 p-4">
              <div className="flex items-center justify-center pb-4 font-semi">Uptime</div>
              <div className="flex">
              <div className="flex-1 flex flex-col items-center">
                <div className="flex items-center justify-center w-66 h-66 rounded-full bg-palette-dark-blue text-white">
                  100%
                </div>
                <div className="text-grey-700 mt-2">Last 7 Days</div>
              </div>
              <div className="flex-1 flex flex-col items-center">
                <div className="flex items-center justify-center w-66 h-66 rounded-full bg-palette-dark-blue text-white">
                  100%
                </div>
                <div className="text-grey-700 mt-2">Last 30 Days</div>
              </div>
              <div className="flex-1 flex flex-col items-center">
                <div className="flex items-center justify-center w-66 h-66 rounded-full bg-palette-dark-blue text-white">
                  100%
                </div>
                <div className="text-grey-700 mt-2 ">All time</div>
              </div>
              </div>
            </div>
            <div className="flex flex-col bg-grey-100 p-4 mt-4">
              <div className="flex items-center justify-center pb-4 font-semi">Transactions</div>
              <div className="flex-col text-left">
              <div className="flex-1 flex items-center">
                <div className="flex-1 text-grey-700 mt-2">Last 7 Days</div>
                <div className="flex-1 flex items-center justify-center p-2 rounded bg-palette-dark-blue text-white">
                  10,000
                </div>
              </div>
              <div className="flex-1 flex items-center mt-2">
                <div className="flex-1 text-grey-700 mt-2">Last 30 Days</div>
                <div className="flex-1 flex items-center justify-center p-2 rounded bg-palette-dark-blue text-white">
                  100,000
                </div>
              </div>
              <div className="flex-1 flex items-center mt-2">
                <div className="text-grey-700 mt-2 flex-1">All time</div>
                <div className="flex-1 flex items-center justify-center p-2 rounded bg-palette-dark-blue text-white">
                  10,000,000
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>*/}
        <div className="md:flex-1 lg:mr-4 flex flex-col rounded shadow-medium bg-palette-lighter-blue min-h-full p-4 md:ml-4 mt-4 md:mt-0">
          <div className="text-xl p-2 text-palette-dark-blue">Fees</div>
          <div>
            <div className="flex flex-col">
              <div className="flex flex-col bg-palette-lightest-blue p-4 mt-4">
                <div className="flex-col text-left">
                  <div className="flex-1 flex items-center">
                    <div className="flex-1 text-grey-700 mt-2">Fixed Fee</div>
                    <div className="flex-1 flex items-center justify-center p-2 rounded bg-palette-dark-blue text-white">
                      340
                    </div>
                  </div>
                  <div className="flex-1 flex items-center mt-2">
                    <div className="flex-1 text-grey-700 mt-2">Fee Commission</div>
                    <div className="flex-1 flex items-center justify-center p-2 rounded bg-palette-dark-blue text-white">
                      1%
                    </div>
                  </div>
                  <div className="flex-1 flex items-center mt-2">
                    <div className="text-grey-700 mt-2 flex-1">Fee Limit</div>
                    <div className="flex-1 flex items-center justify-center p-2 rounded bg-palette-dark-blue text-white">
                      No Limit
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex-1 lg:m-auto md:max-w-6xl">
        <div className="flex-row md:flex mx-4 rounded shadow-medium bg-white p-10">
          <img className="flex-shrink mb-4" style={{maxHeight: '152px'}} src={green} alt="100% green energy" />
          <div className="flex-1 text-left md:px-10">
          Our stakepool data centre is powered by electricity from renewable energy sources. A conscious decision has been made to avoid nuclear power and sources that are environmental CO2 polluters. Energy is sourced from the power generated by flowing water.
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default Content
