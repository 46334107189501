import React from 'react';
import Header from './common/Header'
import Hero from './common/Hero'
import Content from './common/Content'
import Footer from './common/Footer'


function App() {
  return (
<div className="bg-grey-100 flex flex-col min-h-screen" >
    <Header />
    <Hero />
    <Content />
    <Footer />
  </div>
  );
}

export default App;
