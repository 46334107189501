import React from 'react'

function Menu() {
  return (
    <div className="flex-1 p-6">
        <div className=""></div>
    </div>
  );
}

export default Menu