import React from 'react'
import logo from '../images/logo2.svg'
import London from '../images/london.jpg'

function Hero() {
  return (
    <div className="flex bg-palette-pale-blue items-center justify-center">
      <div className="flex h-96 w-full">
        <div
          className="hidden w-full sm:flex"
          style={{
            backgroundImage: `url(${London})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        />
      </div>

      <div className="flex flex-col absolute bg-opaque-black">
        <img src={logo} alt="ada.zone" />
        <div className="text-white text-5xl tect-center">ADA.ZONE</div>
        <div className="text-white text-1xl text-center">A UK Cardano Staking Pool</div>
      </div>
    </div>
  )
}

export default Hero
