import React from 'react'

function Footer() {
  return (
    <div className="flex-shrink bg-palette-pale-blue p-6">
        <div className="text-white font-light">&copy; 2019 ADA.ZONE</div>
    </div>
  );
}

export default Footer